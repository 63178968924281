export const COLOR_ARRAY = [
    "#FF9DBA",
    "#E2A6E6",
    "#BBC1E8",
    "#A5DFF9",
    "#91E3EE",
    "#6BD5CB",
    "#A5E5BD",
    "#E7F286",
    "#FFEC78",
    "#FFBF69",
    "#ff9a9e",
    "#fad0c4",
    "#ff7eb3",
    "#a18cd1",
    "#f6d365",
    "#fbc2eb",
    "#a1c4fd",
    "#c2e9fb",
    "#d4fc79",
    "#84fab0",
    "#8fd3f4",
    "#4facfe",
    "#8ddad5",
    "#00f2fe",
    "#b490ca",
    "#96fbc4",
    "#f6f3ff",
    "#72afd3",
    "#feada6",
    "#6f86d6",
    "#e7f0fd",
    "#deecdd",
    "#74ebd5",
    "#9face6",
    "#fbc8d4",
    "#6991c7",
    "#9795f0",
];

export const TierPricing = [
    { from: 0, to: 0, price: 0 },
    { from: 1, to: 49, price: 29 },
    { from: 50, to: 149, price: 69 },
    { from: 150, to: 499, price: 99 },
    { from: 500, to: 999, price: 149 },
    { from: 1000, to: 99999, price: 199 },
];

const allReplace = (str, chars) => {
    var retStr = String(str);
    for (var x in chars) {
        retStr = retStr.replace(new RegExp(x, "g"), chars[x]);
    }
    return retStr;
};

export const decodeHtmlEntity = (str) => {
    var chars = {
        "&amp;": "&",
        "&lt;": "<",
        "&gt;": ">",
        "&quot;": '"',
        "&#x27;": "'",
        "&#x2F;": "/",
        " &#x5C;": "\\",
    };
    return allReplace(str, chars);
    // return String(str).replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&#x27;/g, "'").replace(/&#x2F;/g, "/");
};

export const capitalizeFirstLetter = (string) => {
    const arr = string.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
};

// export const countryTimeZones = (country) => {
//     switch (country) {
//         case "CA":
//             return [
//                 { value: "Canada/Pacific", label: "PST (Vancouver) (GMT-8)" },
//                 { value: "Canada/Mountain", label: "MST (Edmonton) (GMT-7)" },
//                 {
//                     value: "Canada/Saskatchewan",
//                     label: "CST (Regina/Saskatoon) (GMT-6)",
//                 },
//                 { value: "Canada/Central", label: "CST (Winnipeg) (GMT-6)" },
//                 { value: "Canada/Eastern", label: "EST (Toronto) (GMT-5)" },
//                 {
//                     value: "Canada/Newfoundland",
//                     label: "NST (Newfoundland) (GMT-3)",
//                 },
//                 {
//                     value: "Canada/Atlantic",
//                     label: "AST (Halifax / Nova Scotia) (GMT-3)",
//                 },
//                 { value: "Canada/Yukon", label: "MST (Yukon) (GMT-7)" },
//             ];
//         case "ES":
//             return [
//                 { value: "Europe/Madrid", label: "CET (Madrid) (GMT+1)" },
//                 {
//                     value: "Atlantic/Canary",
//                     label: "WET (Canary Islands) (GMT+0)",
//                 },
//             ];

//         default:
//             return [];
//     }
// };


export const countryCodes = [
    { country: "", code: "", label: "", language: "", currency: "" },
    {
        country: "CA",
        code: "CA",
        label: "CA (+1)",
        language: "English",
        currency: "cad",
    },
    {
        country: "US",
        code: "US",
        label: "US (+1)",
        language: "English",
        currency: "usd",
    },
    {
        country: "AU",
        code: "AU",
        label: "AU (+61)",
        language: "English",
        currency: "aud",
    },
    {
        country: "DE",
        code: "DE",
        label: "DE (+49)",
        language: "German",
        currency: "eur",
    },
    {
        country: "GB",
        code: "GB",
        label: "UK (+44)",
        language: "English",
        currency: "gbp",
    },
    {
        country: "FR",
        code: "FR",
        label: "FR (+33)",
        language: "French",
        currency: "eur",
    },
    {
        country: "NZ",
        code: "NZ",
        label: "NZ (+64)",
        language: "English",
        currency: "nzd",
    },
    {
        country: "SG",
        code: "SG",
        label: "SG (+65)",
        language: "English",
        currency: "sgd",
    },
    {
        country: "ES",
        code: "ES",
        label: "ES (+34)",
        language: "Spanish",
        currency: "eur",
    },

    {
        country: "CZ",
        code: "CZ",
        label: "CZ (+420)",
        language: "Czech",
        currency: "czk",
    },
];


export const LANGUAGE_LIST = () => {
    const uniqueLanguages = new Set();
    countryCodes.forEach(item => {
        if (item.language) {
            uniqueLanguages.add(item.language);
        }
    });
    return Array.from(uniqueLanguages);
}


export const TIME_ZONES = [
    {
        country: "CA",
        value: "America/St_Johns",
        label: "Newfoundland and Labrador America/St_Johns"
    },
    {
        country: "CA",
        value: "America/Halifax",
        label: "Prince Edward Island, Nova Scotia, New Brunswick America/Halifax"
    },
    {
        country: "CA",
        value: "America/Montreal",
        label: "Quebec America/Montreal"
    },
    {
        country: "CA",
        value: "America/Toronto",
        label: "Ontario America/Toronto"
    },
    {
        country: "CA",
        value: "America/Winnipeg",
        label: "Manitoba America/Winnipeg"
    },
    {
        country: "CA",
        value: "America/Regina",
        label: "Saskatchewan America/Regina"
    },
    {
        country: "CA",
        value: "America/Edmonton",
        label: "Alberta America/Edmonton"
    },
    {
        country: "CA",
        value: "America/Vancouver",
        label: "British Columbia America/Vancouver"
    },
    {
        country: "CA",
        value: "America/Whitehorse",
        label: "Yukon America/Whitehorse"
    },
    {
        country: "CA",
        value: "America/Yellowknife",
        label: "Northwest Territories America/Yellowknife"
    },
    {
        country: "CA",
        value: "America/Iqaluit",
        label: "Nunavut (East) America/Iqaluit"
    },
    {
        country: "CA",
        value: "America/Rankin_Inlet",
        label: "Nunavut (Central) America/Rankin_Inlet"
    },
    {
        country: "CA",
        value: "America/Cambridge_Bay",
        label: "Nunavut (West) America/Cambridge_Bay"
    },
    {
        country: "US",
        value: "America/New_York",
        label: "Eastern Time (ET) - New York America/New_York"
    },
    {
        country: "US",
        value: "America/Detroit",
        label: "Eastern Time (ET) - Michigan America/Detroit"
    },
    {
        country: "US",
        value: "America/Kentucky/Louisville",
        label: "Eastern Time (ET) - Kentucky America/Kentucky/Louisville"
    },
    {
        country: "US",
        value: "America/Indiana/Indianapolis",
        label: "Eastern Time (ET) - Indiana America/Indiana/Indianapolis"
    },
    {
        country: "US",
        value: "America/Chicago",
        label: "Central Time (CT) - Illinois, Wisconsin, Alabama, Arkansas, Iowa, Louisiana, Minnesota, Mississippi, Missouri, Oklahoma, Tennessee, Texas America/Chicago"
    },
    {
        country: "US",
        value: "America/Indiana/Knox",
        label: "Central Time (CT) - Indiana (Northwest) America/Indiana/Knox"
    },
    {
        country: "US",
        value: "America/Denver",
        label: "Mountain Time (MT) - Colorado, Montana, Wyoming, New Mexico, Utah America/Denver"
    },
    {
        country: "US",
        value: "America/Phoenix",
        label: "Mountain Time (MST) - Arizona America/Phoenix"
    },
    {
        country: "US",
        value: "America/Los_Angeles",
        label: "Pacific Time (PT) - California, Nevada, Washington America/Los_Angeles"
    },
    {
        country: "US",
        value: "America/Anchorage",
        label: "Alaska Time (AKT) - Alaska America/Anchorage"
    },
    {
        country: "US",
        value: "America/Adak",
        label: "Hawaii-Aleutian Time (HAT) - Hawaii, Aleutian Islands America/Adak"
    },
    {
        country: "US",
        value: "Pacific/Honolulu",
        label: "Hawaii Time (HT) - Hawaii America/Honolulu"
    },


    {
        country: "AU",
        value: "Australia/Perth",
        label: "Australian Western Standard Time (AWST) UTC+8",
    },
    {
        country: "AU",
        value: "Australia/Eucla",
        label: "Australian Central Western Standard Time (ACWST) UTC+8:45",
    },
    {
        country: "AU",
        value: "Australia/Darwin",
        label: "Australian Central Standard Time (ACST) UTC+9:30",
    },
    {
        country: "AU",
        value: "Australia/Sydney",
        label: "Australian Eastern Standard Time (AEST) UTC+10",
    },
    {
        country: "AU",
        value: "Australia/Melbourne",
        label: "Australian Eastern Daylight Time (AEDT) UTC+11",
    },
    {
        country: "AU",
        value: "Australia/Lord_Howe",
        label: "Lord Howe Standard Time (LHST) UTC+10:30",
    },
    {
        country: "AU",
        value: "Pacific/Norfolk",
        label: "Norfolk Island Time (NFT) UTC+11:30",
    },
    {
        country: "DE",
        value: "Europe/Berlin",
        label: "Central European Time (CET) GMT+1",
    },
    {
        country: "ES",
        value: "Europe/Madrid",
        label: "Central European Summer Time Madrid, Spain (GMT+2)",
    },
    {
        country: "GB",
        value: "Europe/London",
        label: "GMT (Greenwich Mean Time GMT+0)",
    },
    {
        country: "CZ",
        value: "Europe/Prague",
        label: "Central European Time (CET) GMT+1",
    },
    {
        country: "NZ",
        value: "Pacific/Auckland",
        label: "New Zealand Daylight Time (NZDT) UTC+13",
    },
    {
        country: "SG",
        value: "Asia/Singapore",
        label: "Singapore Time (SGT) UTC+8",
    },
    {
        country: "FR",
        value: "Europe/Paris",
        label: "Central European Time (CET) GMT+1",
    },
];
