import React, { Component } from "react";
import { connect } from "react-redux";
import CreateNewBusinessForm from "../../components/business/CreateNewBusinessForm";
import { getBusinessStatus } from "../../redux/actions/admin/business-status";
import { getBusinessType } from "../../redux/actions/admin/business-type";
import Loading from "../../layouts/Loading/Loading";
import { getAllProvincesCities } from "../../redux/actions/province-city/province-city";
import { Tabs, Tab, Button, Form, Row, Col, Alert } from "react-bootstrap";
import CreatePaymentProfileForm from "../../components/business/CreatePaymentProfileForm";
import { createBusiness } from "../../redux/actions/business/business";
import ErrorSuccess from "../../components/ErrorSuccess/ErrorSuccess";
import {
    formatPhoneNumber,
    validatePhoneNumber,
} from "../../utils/common/FormatPhoneNumber/FormatPhoneNumber";
import { countryCodes, } from "../../utils/utility";
import { parsePhoneNumber } from "awesome-phonenumber";



class CreateBusinessPage extends Component {
    state = {
        business: {
            name: "",
            businessType: [],
            address: "",
            phone: "",
            email: "",
            urlName: "",
            province: "",
            city: "",
            language: "English",
            timeZone: "",
            isFirstLogin: true,
            country: "",
        },
        country: "",
        currency: "cad",
        paymentProfile: {
            paymentType: "Credit Card",
            rate: 0,
            pricePerBooking: 1.99,
            services: [
                {
                    name: "Unlimited Email Notification",
                    price: 0,
                    quantity: 0,
                    discountType: "percentage",
                    discountValue: "0",
                    description: "",
                },
                {
                    name: "Social Media Support",
                    price: 0,
                    quantity: 0,
                    discountType: "percentage",
                    discountValue: "0",
                    description: "",
                },
                {
                    name: "Website Design And Setup Fee",
                    price: 0,
                    quantity: 0,
                    discountType: "percentage",
                    discountValue: "0",
                    description: "",
                },
                {
                    name: "Domain Name, Hosting Service And Ssl Certificate",
                    price: 0,
                    quantity: 0,
                    discountType: "percentage",
                    discountValue: "0",
                    description: "",
                },
                {
                    name: "Advertising Fee",
                    price: 0,
                    quantity: 0,
                    discountType: "percentage",
                    discountValue: "0",
                    description: "",
                },
                {
                    name: "Online Gift Card Stripe Integration",
                    price: 0,
                    quantity: 0,
                    discountType: "percentage",
                    discountValue: "0",
                    description: "",
                }
            ],
            taxes: [],
            defaultServicesInvalid: false,
        },
        creditPaymentOnly: false,
        updateForm: 0,
        errors: [],
        displayErrorSuccess: false,
    };

    componentDidMount() {
        this.props.getBusinessType();
        this.props.getAllProvincesCities();
        this.props.getBusinessStatus();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.business.business !== prevProps.business.business &&
            this.props.business.business !== null
        ) {
            this.props.history.push(
                `/businesses/${this.props.business.business._id}`
            );
        }
    }

    handleRemoveBusinessType = (businessTypeId) => {
        let { business, updateForm } = this.state;
        business.businessType = business.businessType.filter(
            (type) => type !== businessTypeId
        );
        updateForm++;
        this.setState({
            business,
            updateForm,
        });
    };

    hangleChangeProvinceCity = (value, field) => {
        let { business, updateForm, paymentProfile } = this.state;
        business[field] = value;
        updateForm++;
        if (field === "province" && value) {
            let province = this.props.provinceCity.provinces.filter(
                (province) => province._id === value
            )[0];
            paymentProfile.taxes = province.taxes;
            business.language = province.language;
            business.timeZone = province.timeZone;
        }
        if (field === "country") {
            const selectedCountry = countryCodes.filter(
                (countryCode) => countryCode.country === value
            )[0];
            const countryCode = selectedCountry.code;
            business.country = countryCode;

            business.phone = formatPhoneNumber(business.phone, countryCode);
            paymentProfile.paymentType =
                value !== "CA" ? "Credit Card" : paymentProfile.paymentType;
            paymentProfile.currency = selectedCountry.currency;
            if (value === "CA") {
                paymentProfile.taxes = [{ name: "GST", rate: 5 }];
            } else {
                paymentProfile.taxes = [];
            }

            this.setState({
                country: value,
                creditPaymentOnly: value !== "CA" ? true : false,
                paymentProfile,
            });
        }
        this.setState({
            business,
            updateForm,
        });
    };

    handleAddBusinessType = (value) => {
        let { business, updateForm } = this.state;
        const businessTypeId = value._id
        if (!business.businessType.includes(businessTypeId) && businessTypeId) {
            business.businessType = [...business.businessType, businessTypeId];
            updateForm++;
            this.setState({
                business,
                updateForm,
            });
        } else {
            business.businessType = business.businessType.filter(
                (type) => type !== businessTypeId
            );
            updateForm++;
            this.setState({
                business,
                updateForm,
            });
        }
    }

    handleChangeBusiness = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        let { business, updateForm } = this.state;

        if (field === "phone") {
            value = formatPhoneNumber(value, business.country)
        }
        if (field === "name") {
            business.urlName = value
                .trim()
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .replace(/\s+/g, "-")
                .toLowerCase();
        }

        if (field === "businessType") {
            if (!business.businessType.includes(value) && value) {
                business.businessType = [...business.businessType, value];
            }
        } else {
            business[field] = value;
        }
        updateForm++;

        this.setState({
            business,
            updateForm,
        });
    };

    addTax = () => {
        let { paymentProfile } = this.state;
        let copyPaymentProfile = JSON.parse(JSON.stringify(paymentProfile));
        const newTax = { name: "", rate: 0 };

        copyPaymentProfile.taxes = [...copyPaymentProfile.taxes, newTax];
        this.setState({
            paymentProfile: copyPaymentProfile,
        });
    };

    removeTax = (index) => {
        let { paymentProfile } = this.state;
        let copyPaymentProfile = JSON.parse(JSON.stringify(paymentProfile));
        copyPaymentProfile.taxes.splice(index, 1);
        this.setState({
            paymentProfile: copyPaymentProfile,
        });
    };

    handleChangeTax = (index, field, value) => {
        let { paymentProfile } = this.state;
        let copyPaymentProfile = JSON.parse(JSON.stringify(paymentProfile));
        let newTaxes = copyPaymentProfile.taxes;
        newTaxes[index][field] = value;
        this.setState({
            paymentProfile: copyPaymentProfile,
        });
    };

    handleChangePayment = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        let { paymentProfile, updateForm } = this.state;
        paymentProfile[field] = value;
        updateForm++;

        this.setState({
            paymentProfile,
            updateForm,
        });
    };

    validateDefaultServices = () => {
        let { paymentProfile, updateForm } = this.state;
        let defaultServicesInvalid = false;

        paymentProfile.services.forEach((service) => {
            if (service.price < 0 || service.quantity < 0 || !service.name) {
                defaultServicesInvalid = true;
            }
        });
        paymentProfile.defaultServicesInvalid = defaultServicesInvalid;
        updateForm++;

        this.setState({ paymentProfile, updateForm });
    };

    handleChangeServiceName = (value, index, fieldName) => {
        let { paymentProfile, updateForm } = this.state;
        let updatedService = paymentProfile.services[index];
        updatedService[fieldName] = value;
        updateForm++;
        this.setState(
            {
                paymentProfile,
                updateForm,
            },
            () => {
                this.validateDefaultServices();
            }
        );
    };

    addMoreService = () => {
        let { paymentProfile, updateForm } = this.state;
        const newService = {
            name: "",
            price: 0.0,
            quantity: 1,
            description: "",
            discountType: "percentage",
            discountValue: 0,
        };
        paymentProfile.services = [...paymentProfile.services, newService];
        updateForm++;
        this.setState(
            {
                paymentProfile,
                updateForm,
            },
            () => {
                this.validateDefaultServices();
            }
        );
    };

    removeService = (index) => {
        let { paymentProfile, updateForm } = this.state;
        paymentProfile.services.splice(index, 1);
        updateForm++;
        this.setState(
            {
                paymentProfile,
                updateForm,
            },
            () => {
                this.validateDefaultServices();
            }
        );
    };

    camelCaseToWords = (string) => {
        const words = string.replace(/([A-Z])/g, " $1");
        return words.charAt(0).toUpperCase() + words.slice(1);
    };

    handleEmptyFields = () => {
        var { business, paymentProfile, errors } = this.state;
        errors = [];
        for (const [key, value] of Object.entries(business)) {
            if (!value || value.length === 0) {
                let error = `${this.camelCaseToWords(key)} field is missing!`;
                if (!errors.includes(error)) {
                    errors.push(error);
                }
            }
        }
        for (const [key, value] of Object.entries(paymentProfile)) {
            if ((key === "rate" || key === "pricePerBooking") && value === "") {
                let error = `${this.camelCaseToWords(key)} field is missing!`;
                if (!errors.includes(error)) {
                    errors.push(error);
                }
            }
        }
        this.setState({
            errors,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { business, paymentProfile, errors } = this.state;
        let valid = true;

        if (!validatePhoneNumber(business.phone, business.country)) {
            let error = "Phone Number is invalid!";
            if (!errors.includes(error)) {
                errors.push(error);
            }
            valid = false;
        }
        if (business.businessType.length === 0) {
            let error = "Business type is not specified!";
            errors.push(error);
            valid = false;
        }
        this.setState({
            errors,
        });

        if (valid) {
            this.setState({
                errors: [],
            });
            this.setState({
                displayErrorSuccess: true,
            });
            business.phone = parsePhoneNumber(
                business.phone,
                business.country
            ).getNumber();
            this.props.createBusiness(business, paymentProfile);
        }
    };

    render() {
        const { businessType, provinceCity, businessStatus, business } =
            this.props;
        const { errors, displayErrorSuccess } = this.state;

        if (
            businessType.loading ||
            provinceCity.loading ||
            businessStatus.loading
        ) {
            return <Loading />;
        }


        return (
            <div>
                <h1>Create a new business</h1>
                <Form onSubmit={this.handleSubmit}>
                    <Tabs
                        defaultActiveKey='profile'
                        id='uncontrolled-tab-example'
                    >
                        <Tab eventKey='profile' title='Profile'>
                            <CreateNewBusinessForm
                                businessTypes={businessType.businessTypes}
                                business={this.state.business}
                                handleRemoveBusinessType={
                                    this.handleRemoveBusinessType
                                }
                                handleAddBusinessType={this.handleAddBusinessType}
                                hangleChangeProvinceCity={
                                    this.hangleChangeProvinceCity
                                }
                                handleChangeBusiness={this.handleChangeBusiness}
                                updateForm={this.state.updateForm}
                                country={this.state.country}
                            />
                        </Tab>
                        <Tab eventKey='payment-profile' title='Payment Profile'>
                            <CreatePaymentProfileForm
                                addTax={this.addTax}
                                removeTax={this.removeTax}
                                handleChangeTax={this.handleChangeTax}
                                paymentProfile={this.state.paymentProfile}
                                handleChangePayment={this.handleChangePayment}
                                validateDefaultServices={
                                    this.validateDefaultServices
                                }

                                handleChangeServiceName={
                                    this.handleChangeServiceName
                                }
                                addMoreService={this.addMoreService}
                                removeService={this.removeService}
                                updateForm={this.state.updateForm}
                                creditPaymentOnly={this.state.creditPaymentOnly}
                            />
                        </Tab>
                    </Tabs>

                    <Row className='mt-3'>
                        <Col>
                            {this.props.business.loading ? (
                                <Loading />
                            ) : (
                                <Button
                                    disabled={
                                        this.state.paymentProfile
                                            .defaultServicesInvalid
                                    }
                                    onClick={this.handleEmptyFields}
                                    variant='primary'
                                    type='submit'
                                >
                                    Create
                                </Button>
                            )}
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col>
                            {errors.length > 0 &&
                                errors.map((error) => {
                                    return (
                                        <Alert key={error} variant='danger'>
                                            {error}
                                        </Alert>
                                    );
                                })}
                        </Col>
                    </Row>
                    {displayErrorSuccess && (
                        <Row className='mt-3'>
                            <Col>
                                <ErrorSuccess
                                    error={business.error}
                                    message={business.message}
                                />
                            </Col>
                        </Row>
                    )}
                    {/* {console.log(this.state)} */}
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    businessType: {
        businessTypes: state.businessType.businessTypes,
        loading: state.businessType.loading,
        error: state.businessType.error,
        message: state.businessType.message,
    },
    provinceCity: {
        cities: state.provinceCity.cities,
        provinces: state.provinceCity.provinces,
        loading: state.provinceCity.loading,
        error: state.provinceCity.error,
        message: state.provinceCity.message,
    },
    businessStatus: {
        businessStatuses: state.businessStatus.businessStatuses,
        provinces: state.businessStatus.provinces,
        loading: state.businessStatus.loading,
        error: state.businessStatus.error,
        message: state.businessStatus.message,
    },
    business: {
        businesses: state.business.businesses,
        business: state.business.business,
        loading: state.business.loading,
        error: state.business.error,
        message: state.business.message,
    },
});

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessType: () => dispatch(getBusinessType()),
        getBusinessStatus: () => dispatch(getBusinessStatus()),
        getAllProvincesCities: () => dispatch(getAllProvincesCities()),
        createBusiness: (business, paymentProfile) =>
            dispatch(createBusiness(business, paymentProfile)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBusinessPage);
