import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";
import API from "../../utils/API/API";
import { logout } from "../../redux/actions/employee-account/login";
// import Logo from "../../assets/images/dashbooking.svg"
import { Redirect } from 'react-router'
import { AccountantNavigation } from "./AccountantNavigation";
import { EmployeeNavigation } from "./EmployeeNavigation";
import { AdminNavigation } from "./AdminNavigation";
import { DeveloperNavigation } from "./DeveloperNavigation";
const mapStateToProps = state => {
  return {
    user: state.authenticate.user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout())
  };
}

class NavigationContainer extends Component {
  handleLogout = e => {
    e.preventDefault();
    API.post("/employee-accounts/logout")
      .then(res => {
        this.props.logout();

      })
      .catch(err => {
        console.log(err);
        this.props.logout();
        return <Redirect to="/login" />
      });
  };

  render() {
    const isLoggegIn = this.props.user.authenticated;
    const userRole = this.props.user.role



    return (
      <div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand href="#"
            style={{
              backgroundImage: "url('/assets/images/dashbooking.svg')",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "40px",
              width: "200px"
            }}
          >
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          {isLoggegIn && (
            <Navbar.Collapse id="responsive-navbar-nav">
              {userRole === "accountant" && <AccountantNavigation />}
              {userRole === "employee" && <EmployeeNavigation />}
              {userRole === "admin" && <AdminNavigation />}
              {userRole === "developer" && <DeveloperNavigation />}
              <Nav>
                <Nav.Link
                  eventKey={2}
                  href="/login"
                  onClick={this.handleLogout}
                >
                  Log Out
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          )}
          {!isLoggegIn && (
            <Navbar.Collapse>
              <Nav></Nav>
              <Nav>
                <Nav.Link eventKey={2} href="/login">
                  Login
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          )}
        </Navbar>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationContainer);
